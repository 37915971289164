<template>
    <action-button
        v-show="showIcon"
        :icon="icon"
        class="custom-user-action"
        @click="callFunction()" />
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';

export default {
    name: 'UserActionButton',
     components: {
        ActionButton,
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        showIcon: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        callFunction() {
            this.$emit('callback');
        }
    }
};
</script>

<style>
.custom-user-action .action-button-text{
    white-space: nowrap;
    display: block;
}
</style>
